import angular from 'angular';
import { IVagas } from '../models/vagas.model';
import { CargosService } from '../../../Cargos/cargos.service';
import { TrabalhadoresService } from '../../../Trabalhadores/trabalhadores.service';
import { HorarioDetalhadoModalService } from '../../Admissao/modais/modal-horario-detalhado/horario-detalhado.modal.service';
import { HorariosService } from '../../../Horarios/horarios.service';

export class MeurhVagasFormController {

  static $inject = [
    '$scope',
    '$http',
    'nsjRouting',
    '$stateParams',
    '$rootScope',
    'CargosService',
    'TrabalhadoresService',
    'NewToaster',
    'HorarioDetalhadoModalService',
    'HorariosService',
  ];

  public entity: IVagas;
  public form: angular.IFormController;
  public action: string;
  public unidadeSalarioFixoSelect: Array<any>;
  public listaTrabalhadores: Array<any>;

  constructor(
    public $scope: angular.IScope,
    public $http: angular.IHttpService,
    public nsjRouting: any,
    public $stateParams: angular.ui.IStateParamsService,
    public $rootScope: angular.IRootScopeService & {
        liberadoTela: (arg: string) => boolean,
        session: any,
    },
    public CargosService: CargosService,
    public TrabalhadoresService: TrabalhadoresService,
    public NewToaster: any,
    public HorarioDetalhadoModalService: HorarioDetalhadoModalService,
    public HorariosService: HorariosService,
  ) {
    this.TrabalhadoresService.constructors['tipo'] = '1'; // colaborador contribuinte
    this.listaTrabalhadores = this.TrabalhadoresService.reload();
    this.unidadeSalarioFixoSelect = [
      {
        nomeUnidade: 'Hora',
        valor: 1
      }, {
        nomeUnidade: 'Dia',
        valor: 2
      }, {
        nomeUnidade: 'Semana',
        valor: 3
      }, {
        nomeUnidade: 'Mês',
        valor: 4
      }, {
        nomeUnidade: 'Tarefa',
        valor: 5
      }, {
        nomeUnidade: 'Variável',
        valor: 6
      }
    ];
  }

  async $onInit(): Promise<void> {
    if (this.action === 'edit' && this.entity.nivelcargo?.cargo) {
      this.CargosService.get(this.entity.nivelcargo.cargo)
        .then((response: any): void => {
          this.entity.cargo = response;
        }).catch((e: any): void => {
          console.log(e);
        });
    }
    if (this.action === 'insert') {
      this.entity.ativa = true;
    }

    this.verificaGestores();

    // se estiver visualizando/editando vaga, precisa fazer get de horário para obter o resumo da jornada semanal
    if (this.action !== 'insert' && this.entity?.horario) {

      await this.HorariosService.get(this.entity.horario.horario, true).then((horarioBuscado) => {
        this.entity.horario = horarioBuscado;
      })
      .catch((error) => {
        this.NewToaster.pop({
          type: 'error',
          title: 'Não foi possível carregar o horário'
        });
      });

    }

  }

  verificaCargo(): { cargo: string } | {} {
    if (this.entity.cargo) {
      if (this.entity.cargo.cargo) {
        return { 'cargo': this.entity.cargo.cargo };
      }
    }
    return {};
  }

  verificaEstabelecimento(): { estabelecimento: string } {
    if (this.entity.estabelecimento) {
      return { 'estabelecimento': this.entity.estabelecimento };
    } else {
      return { 'estabelecimento': this.$rootScope.session.entities.estabelecimento.estabelecimento };
    }
  }

  verificaEmpresa(): { empresa: string, descricaoHorario: boolean } {
    if (this.entity.empresa) {
      return { 'empresa': this.entity.empresa, 'descricaoHorario': true };
    } else {
      return { 'empresa': this.$rootScope.session.entities.empresa.empresa, 'descricaoHorario': true };
    }
  }

  verificaGestores(): void {
    if (!this.entity.gestores) {
      this.entity.gestores = [];
    } else {
      this.entity.gestores = this.entity.gestores.map((gestor: any) => {
        if (gestor.gestor.trabalhador) {
          return gestor.gestor;
        } else {
          return {
            nome: gestor.identificacaonasajongestor
          };
        }
      });
    }
  }

  informaEstabelecimento(): void {
    if (this.entity.departamento) {
      this.entity.departamento.estabelecimento = this.entity.estabelecimento ? this.entity.estabelecimento : this.$rootScope.session.entities.estabelecimento.estabelecimento;
    }
  }

  alteraCargo(): void {
    if (this.entity.cargo) {
      this.$rootScope.$broadcast('form-nivelcargo_refresh', { cargo: this.entity.cargo.cargo });
      this.entity.nivelcargo = null;
    }
  }

  limpaDescricaoSalario(): void {
    this.entity.descricaosalariovariavel = null;
  }

  public verificaLotacao(): { lotacao?: string } {
    if (!this.entity.lotacao) {
      return {};
    }
    return { 'lotacao': this.entity.lotacao!.lotacao };
  }

  public verificaAmbiente(): { ambiente?: string } {
    if (!this.entity.ambiente) {
      return {};
    }
    return { 'ambiente': this.entity.ambiente!.ambiente };
  }

  public alteraLotacao(): void {
    if (this.entity.lotacao) {
      this.$rootScope.$broadcast('ambiente_refresh', { lotacao: this.entity.lotacao.lotacao });
      this.entity.ambiente = null;
      this.entity.condicaoAmbiente = null;
    }
  }

  public alteraAmbiente(): void {
    if (this.entity.ambiente) {
      this.$rootScope.$broadcast('condicoesambientestrabalho_refresh', { ambiente: this.entity.ambiente.ambiente });
      this.entity.condicaoambientetrabalho = null;
    }
  }

  public abrirModalHorarioDetalhado() {

    let modal = this.HorarioDetalhadoModalService.open(this.entity.horario, {
        empresa: this.$rootScope.session.entities.empresa.empresa,
        estabelecimento: this.$rootScope.session.entities.estabelecimento.estabelecimento,
        action: this.action
    });

    modal.result.then((horarioSelecionadoModal: any): void => {
        this.entity.horario = horarioSelecionadoModal;
    })
    .catch((error: any): void => {/**/});
  }

  public alteraNivelcargo(): void {
    this.entity.salariofixo = this.entity.nivelcargo ? this.entity.nivelcargo.valorsalario : null;

  }

}
