import angular from 'angular';
import moment from 'moment';
import { IGestoresTrabalhador, ITransferenciaColaboradores } from '../models/transferenciacolaboradores.model';
import { MeurhTransferenciaColaboradoresService } from '../transferenciacolaboradores.service';
import { MeurhImportacaoTransferenciaService } from '../importacaotransferencia.service';

export class MeurhTransferenciaColaboradoresNewController implements angular.IController {
    static $inject = [
        '$rootScope',
        '$scope',
        '$stateParams',
        '$state',
        'MeurhTransferenciaColaboradoresService',
        'MeurhImportacaoTransferenciaService',
        'entity',
        'NewToaster',
        'ModalConfirmService',
        '$q',
        '$transitions'
    ];

    public constructors: any = {};
    public action: string = 'insert';
    public form: angular.IFormController;
    public entitySave: Partial<ITransferenciaColaboradores> = {};
    public busy: boolean = false;
    public emLote: boolean;
    public allowRefresh: boolean = false;
    public formchanged: boolean = false;

    constructor(
        public $rootScope: angular.IRootScopeService & {
            liberadoTela: (arg: string) => boolean
        },
        public $scope: angular.IScope,
        public $stateParams: angular.ui.IStateParamsService,
        public $state: angular.ui.IStateService,
        public entityService: MeurhTransferenciaColaboradoresService,
        public importacaoService: MeurhImportacaoTransferenciaService,
        public entity: ITransferenciaColaboradores,
        public NewToaster: any,
        public ModalConfirmService: any,
        public $q: any,
        public $transitions: any) {

        this.emLote = this.$stateParams.tipo === 'lote' ? true : false;

        if (!this.emLote) {
            this.$scope.$watchGroup([
                'mrh_trnsfrncclbrdrs_frm_nw_cntrllr.entity.trabalhador',
                'mrh_trnsfrncclbrdrs_frm_nw_cntrllr.entity.vaga'
            ], (newValue, oldValue) => {
                if (newValue !== oldValue) {
                    this.formchanged = true;
                }
            });
        }

        document.querySelector('form')?.addEventListener('change', (e) => {
            this.formchanged = true;
        });

        this.onSubmitSuccess();
        this.onSubmitError();
        this.exitScreenDescartarModal();
        document.onkeydown = this.refreshDescartarModal;
        this.allowRefresh = true;
    }

    createEntity(): void {
        if (this.emLote) {
            let formData = new FormData();
            formData.append('planilha', this.entity.fileUpload[0]);
            this.entitySave = formData;
        } else {
            this.entitySave.trabalhador = this.entity.trabalhador;
            this.entitySave.datainicio = this.entity.datainicio;
            this.entitySave.datafim = this.entity.datafim;
            this.entitySave.estabelecimento = this.entity.trabalhador['estabelecimentoobj'].estabelecimento;
            this.entitySave.estabelecimentodestino = this.entity.estabelecimentodestino;
            this.entitySave.departamento = this.entity.departamento;
            this.entitySave.lotacao = this.entity.lotacao;
            this.entitySave.grupodeponto = this.entity.grupodeponto;
            this.verificaGestores();
            this.verificarVaga();
        }
    }

    verificaGestores(): void {
        this.entitySave.gestores = [];
        this.entity.gestores.map((gestor: IGestoresTrabalhador) => {
            if (gestor.gestor) {
                this.entitySave.gestores?.push({ 'gestor': gestor.gestor });
            } else {
                this.entitySave.gestores?.push({ 'identificacaonasajongestor': gestor.email });
            }
        });
    }

    verificarVaga() : void {
        if (!this.entity.vaga) {
            this.entitySave.vaga = {'vaga' : null};
        }  else {
            this.entitySave.vaga = this.entity.vaga;
        }
    }

    verificaObj(): boolean {
        if (this.emLote) {
            if (this.valorExiste(this.entity.fileUpload)) {
                return false;
            } else {
                return true;
            }
        } else {
            if (
            this.valorExiste(this.entity.trabalhador) && this.valorExiste(this.entity.datainicio)
            ) {
                return false;
            } else {
                return true;
            }
        }
    }

    salvar(): void {
        const confirmText = this.emLote ?
            'Você tem certeza que deseja enviar a transferência em lote?' :
            'Você tem certeza que deseja enviar a transferência de  '  + this.entity.trabalhador.nome + '  para ser iniciada em ' + moment(this.entity.datainicio).format('DD/MM/YYYY') + '?';

        let customText = {
            title: 'Enviar transferência de colaboradores',
            confirmText,
            closeButton: 'Cancelar',
            confirmButton: 'Sim, enviar transferência',
        };

        let confirm = this.ModalConfirmService.open(this.entity, 'Transferência de colaboradores', false, customText);
        confirm.result
        .then((): void => {
            if (this.form.$valid && !this.entity.$$__submitting) {
                this.busy = true;
                this.createEntity();
                if (this.emLote) {
                    this.allowRefresh = false;
                    this.importacaoService.save(this.entitySave);
                } else {
                    const params = {
                        empresa: this.entity.trabalhador['empresaobj'].empresa,
                        estabelecimento: this.entity.trabalhador['estabelecimentoobj'].estabelecimento
                    };
                    this.allowRefresh = false;
                    this.entityService.save(this.entitySave, params);
                }
            } else {
                this.NewToaster.pop({
                    type: 'error',
                    title: 'Alguns campos do formulário apresentam erros.'
                });
            }
        })
        .catch(function () {/**/});
    }

    onSubmitSuccess(): void {
        this.$scope.$on('meurh_importacoestransferencias_submitted', (event: angular.IAngularEvent, args: any): void => {
            this.NewToaster.pop({
                type: 'success',
                title: 'As transferências foram enviadas com sucesso!'
            });
            this.$state.go('meurh_solicitacoestransferenciasvagas', angular.extend(this.importacaoService.constructors));
        });
        this.$scope.$on('meurh_solicitacoestransferenciasvagas_submitted', (event: angular.IAngularEvent, args: any): void => {
            this.NewToaster.pop({
                type: 'success',
                title: 'A transferência foi enviada com sucesso!'
            });
            this.$state.go('meurh_solicitacoestransferenciasvagas_show', {'solicitacao': args.entity.solicitacao });
        });
    }

    onSubmitError(): void {
        this.$scope.$on('meurh_solicitacoestransferenciasvagas_submit_error', this.errorMessage.bind(this));
        this.$scope.$on('meurh_importacoestransferencias_submit_error', this.errorMessage.bind(this));
    }

    errorMessage(event: angular.IAngularEvent, args: any): void {
        this.busy = false;
        if (args.response.status === 409) {
            this.NewToaster.pop({
                type: 'error',
                title: args.response.data.message
            });
        } else if (typeof (args.response.data.message) !== 'undefined' && args.response.data.message) {
            if (args.response.data.message === 'Validation Failed') {
                let message = args.response.data.errors.errors[0];

                this.NewToaster.pop({
                    type: 'error',
                    title: 'Erro de Validação',
                    body: message,
                    bodyOutputType: 'trustedHtml'
                });
            } else {
                this.NewToaster.pop({
                    type: 'error',
                    title: args.response.data.message
                });
            }
        } else {
            this.NewToaster.pop({
                type: 'error',
                title: 'Ocorreu um erro ao enviar a Transferência!'
            });
        }
    }

    valorExiste(valor: any): boolean {
        return valor !== '' && valor !== undefined && valor !== null ? true : false;
    }

    buttonDescartarModal() {
        if (this.formchanged) {
            let confirm = this.ModalConfirmService.OpenDescartar(this.entity, ' criação', false);
            confirm.result.then((): void => {
                this.allowRefresh = false;
                this.$state.go('meurh_solicitacoestransferenciasvagas', angular.extend(this.entityService.constructors));
            })
                .catch((error: any): void => {/* */ });
        } else {
            this.$state.go('meurh_solicitacoestransferenciasvagas', angular.extend(this.entityService.constructors));
        }
    }

    refreshDescartarModal = (e: any) => {
        e = e || window.event;
        if (this.formchanged && this.allowRefresh) {
            if (e.keyCode === 116) {
                e.preventDefault();
                let confirm = this.ModalConfirmService.OpenDescartar(this.entity, ' criação', true);
                confirm.result.then((): void => {
                    this.allowRefresh = false;
                    window.location.reload();
                })
                    .catch((error: any): void => {/* */ });
            }
        }
    }

    exitScreenDescartarModal() {
        this.$transitions.onBefore({}, (trans: any) => {
            let d = this.$q.defer();
            if (trans._targetState._identifier !== 'meurh_solicitacoestransferenciasvagas_new' && this.allowRefresh && this.formchanged) {
                let confirm = this.ModalConfirmService.OpenDescartar(this.entity, ' criação', true);
                confirm.result.then((): void => {
                    this.allowRefresh = false;
                    let modalConfirmed = true;
                    if (modalConfirmed) {
                        d.resolve();
                    } else {
                        d.reject();
                    }
                })
                    .catch(function () { /**/ });
            } else {
                d.resolve();
            }
            return d.promise;
        });
    }
}
